
import magic from '../../assets/MagicEcole.png';
import gilbot from '../../assets/gilbot.png';
import hanbit_media from'../../assets/hanbit_media.png';
import hanbitaen from '../../assets/hanbitaen.png';
import burger from '../../assets/burger.png';
import insight2 from '../../assets/insight_2.png';

const imagesList =[
  {
    img : magic,
    name: "메직에꼴",
    eng : "magic",
    url : 'https://magicecole.com/'
  },
  {
    img : gilbot,
    name : '(주)도서출판 길벗',
    eng:"gilbut",
    url:'https://www.gilbut.co.kr/'
  },
  {
    img : hanbit_media,
    name : '한빛미디어',
    eng :"hanbit_media",
    url : 'https://www.hanbit.co.kr/',
  },
  {
    img : hanbitaen,
    name : '한빛앤',
    eng:"hanbitaen",
    url :'https://www.hanbitn.com/'
  },
  {
    img : burger,
    name : '크라이치즈버거',
    eng:"burger",
    url:'https://www.instagram.com/crycheeseburger.official/'
  },
  {
    img : insight2,
    name : '인사이트',
    eng:"insight",
    url:'https://ebook.insightbook.co.kr/'
  },

]

const Sponsor = () => {
  return (
    <section className="main-section main-sponser-wrap">
      <p className='main-seaction-title' >SPONSORS</p>
      <p className='main-sponser-text  main-section-each-title' >후원사</p>
      <div className="main-sponser-items">
        <ul>
          {
            imagesList.map((item, index)=> (
              <li key={`${index}-${item.name}`}>
                <a href={item.url} target='_blank'>
                  <img className={item.eng}  src={item.img} alt={item.name} />
                </a>
              </li>
            ))
          }

        </ul>

      </div>
  </section>
  )
}

export default Sponsor