// import { useEffect, useState } from 'react'
import './LiveStreaming.css';
import magic from '../../assets/livestream_magic.png';
import gilbot from '../../assets/livesteam_gilbut.png';
import hanbit_media from'../../assets/livestream_hanbit_meida.png';
import hanbitaen from '../../assets/livestream_hanbitaen.png';
import burger from '../../assets/livestream_buger.png';
import insight2 from '../../assets/livestream_insight.png';
import discord from '../../assets/discord.png';
import slido from '../../assets/slido.png';
import { Link, useNavigate } from 'react-router-dom';

const imagesList =[
  {
    img : magic,
    name: "메직에꼴",
    eng : "magic",
    url : 'https://magicecole.com/'
  },
  {
    img : gilbot,
    name : '(주)도서출판 길벗',
    eng:"gilbut",
    url:'https://www.gilbut.co.kr/'
  },
  {
    img : hanbit_media,
    name : '한빛미디어',
    eng :"hanbit_media",
    url : 'https://www.hanbit.co.kr/',
  },
  {
    img : hanbitaen,
    name : '한빛앤',
    eng:"hanbitaen",
    url :'https://www.hanbitn.com/'
  },
  {
    img : burger,
    name : '크라이치즈버거',
    eng:"burger",
    url:'https://www.instagram.com/crycheeseburger.official/'
  },
  {
    img : insight2,
    name : '인사이트',
    eng:"insight",
    url:'https://ebook.insightbook.co.kr/'
  },

]


const GrowthLive = () =>{
  return (
  <>
    <iframe
      src="https://customer-bvf8op1xoa1uyswo.cloudflarestream.com/d98bc7aca628c18048ec90626a457354/iframe"
      allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
      allowFullScreen={true}
      className='livestream-growth'
    ></iframe>
  </>
  )
}

// const ChallengeLive = () => {

//   return(
//     <>
//       <iframe
//       src="https://customer-bvf8op1xoa1uyswo.cloudflarestream.com/c404a541bb3f6e6925d7860c4cdceb9e/iframe"
//       allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
//       allowFullScreen={true}
//       className='livestream-challenge'
//       ></iframe>
//     </>
//   )
// }


const LiveStreaming2 = () => {
  // const [ hall, setHall ] = useState<number | null>(null);
  // const [isAnimating, setIsAnimating] = useState(true); 
  const navigate = useNavigate();
  



  // 도전|| 설장 hall 선택 toggle
  const handleToggleBtn = () => {
    navigate(`/puddingtube-1`)
  }


  // const handleAnimationBtn = () =>{
  //   setIsAnimating(prev => !prev)
  // }

  return (
    <div
      id='livestream-main'
    >
      <article
        className='livestream-article'
      >
        <div
          className='livestream-head'
        >
          <h2>
            성장 Hall Live Stream</h2>
        </div>

        {/* iframe 영역 */}
        <div
          className='livestream-wrap'
        >
          <GrowthLive />

        </div>

        {/* buttons 영역 */}
        <div
          className='livestream-links'
        >
          <ul>
          <li>
            <Link to="https://discord.com/invite/tBZJfpePcp" target='_blank'>
              <img src={discord} alt="푸딩캠프 디스코드"  className='livestream-discord'/>
            </Link>
          </li>
          <li>
            <a href="https://app.sli.do/event/7E6oB925nvBVJMoH4qunF6 " target='_blank'>
              <img src={slido} alt="푸딩캠프 컨퍼런스 슬라이도" 
              className='livestream-slido'
              />
            </a>
          </li>
          <li
            className='livestream-toggle-btn-wrap'
          >
            <button
              onClick={handleToggleBtn}
            >
              <span>
                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_731_6568)">
                  <path d="M13.8334 2.5L17.1667 5.83333L13.8334 9.16667" stroke="#DFE1E4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M8.83337 5.83203H17.1667" stroke="#DFE1E4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M7.16671 10.832L3.83337 14.1654L7.16671 17.4987" stroke="#DFE1E4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M3.83337 14.168H11.3334" stroke="#DFE1E4" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </g>
                  <defs>
                  <clipPath id="clip0_731_6568">
                  <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                  </defs>
                </svg>
              </span>
              도전 hall
            </button>
          </li>
            
          </ul>
        </div>

        {/* 후원 banners 영역 */}
        <div
          className='livestream-banner-wrap'
        >
          {/* <ul
            className={`livestream-banner-wrap-inner ${isAnimating ? 'animating' : 'paused'}`}
          > */}
          <ul
            className={`livestream-banner-wrap-inner`}
          >
            {
              imagesList.map((item, index)=>{
                return(
                  <li
                  key={`${item.eng}-${index}`}
                  >
                    <img 
                    className={`livestream-${item.eng}`}
                    src={item.img} alt={item.name} />
                  </li>
                )
              })
            }
          </ul>
          {/* <ul
            className={`livestream-banner-wrap-inner ${isAnimating ? 'animating' : 'paused'}`}
          > */}
          <ul
            className={`livestream-banner-wrap-inner`}
          >
            {
              imagesList.map((item, index)=>{
                return(
                  <li
                  key={`${item.eng}-${index}`}
                  >
                    <img 
                    className={`livestream-${item.eng}`}
                    src={item.img} alt={item.name} />
                  </li>
                )
              })
            }
          </ul>

          {/* <div>
            <button
              onClick={()=>handleAnimationBtn()}
            >
              {isAnimating ? 'animating' : 'paused'}
            </button>
          </div> */}
        </div>

      </article>
    </div>
  )
}

export default LiveStreaming2
