import Slogan from '../Slogan/Slogan';
import Sponsor from '../Sponsor/Sponsor';
import Speaker from '../Speaker/Speaker';
import backgroundImg from '../../assets/Subtract2.png';
import Event from '../Event/Event';
import MainFaq from '../MainFaq/MainFaq';



const Main = () => {
  return (
    <main>

      <Slogan type="main"  comment="학습과 성장 컨퍼런스 2024" />

      {/* speacker area */}
      <Speaker />

      <div className='main-divide-line' ></div>

      <Event />

      <div className='main-divide-line' ></div>
      {/* sponser area */}
      
      <Sponsor />
      <div className='main-divide-line' ></div>

      {/* Networking area */}
      <MainFaq />

      <div className='main-divide-line' ></div>

      <Slogan type="main" comment="학습과 성장의 컨퍼런스 2024" />

      <img className="main-background-img"  src={backgroundImg} alt="배경이미지" />
    </main>
  );
}

export default Main