import { StrictMode } from 'react'
import * as ReactDOM from "react-dom/client";
import './index.css'
import Header from './components/Header/Header.tsx'
import Footer from './components/Footer/Footer.tsx'
import App from './App.tsx'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Payment from './components/Payment/Payment.tsx';
import SuccessPage from './components/Payment/SuccessPage.tsx';
import RequestSuccessPage from './components/Payment/RequestSuccessPage.tsx';
import FailPage from './components/Payment/FailPage.tsx';
import FNQ from './components/FnQ/FaQ.tsx';
import "./sentry.ts";
import Free from './components/Free/Free.tsx';
import Schedule from './components/schedule/Schedule.tsx';
import SponsorShip from './components/SponsorShip/SponsorShip.tsx';
import SuccessSponsor from './components/SponsorShip/SuccessSponsor.tsx';
import ErrorPage from './components/ErrorPage.tsx';
import FreeSuccess from './components/Free/FreeSuccess.tsx';
import Error500 from './components/Error500.tsx';
import AskMe from './components/askme/AskMe.tsx';
import AskMeSuccess from './components/askme/AskMeSuccess.tsx';
import RequestFailPage from './components/Payment/RequestFailPage.tsx';
import Instroduce from './components/InstroduceSpeaker/InstroduceSpeaker.tsx';
import LiveStreaming1 from './components/LiveStreaming/LiveStreaming1.tsx';
import LiveStreaming2 from './components/LiveStreaming/LiveStreaming2.tsx';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement:<ErrorPage />
  },
  {
    path: "/payment",
    element : <Payment />
  },
  {
    path: "/payment/success",
    element : <SuccessPage />
  },
  {
    path: "/payment/request-success",
    element : <RequestSuccessPage />
  },
  {
    path: "/payment/fail",
    element : <FailPage />
  },
  {
    path: "/payment/request-fail",
    element : <RequestFailPage />
  },
  {
    path: "/fnq",
    element : <FNQ />
  },
  {
    path: "/online",
    element : <Free />
  },
  {
    path: "/online/success",
    element : <FreeSuccess />
  },
  {
    path: "/schedule",
    element : <Schedule />
  },
  {
    path: "/sponsorship",
    element : <SponsorShip />
  },
  {
    path: "/sponsorship/success",
    element : <SuccessSponsor />
  },
  {
    path: "/errors/500",
    element : <Error500 />
  },
  {
    path: "/faq",
    element : <FNQ />
  },
  {
    path: "/ask",
    element : <AskMe />
  },
  {
    path: "/ask/success",
    element : <AskMeSuccess />
  },
  {
    path: "/speakers",
    element : <Instroduce />
  },
  {
    path: "/puddingtube-1",
    element : <LiveStreaming1 />
  },
  {
    path: "/puddingtube-2",
    element : <LiveStreaming2 />
  }
]);

ReactDOM.createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <Header />
    <RouterProvider router={router} />
    <Footer />
  </StrictMode>,
)
